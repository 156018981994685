<template>
        <div class="sales-list">
            <div class="page-title flex justify-between bg-white">
                <h1 class="typography-h1 title">
                    Transakce
                </h1>
            </div>
            <div class="wrapper">
                <div class="controls row justify-end">
                    <div class="flex ">
                        <div class="filter-wrapper">
                            <SalesFilters
                                @click.native.stop=""
                                @clear="clearFilters"
                                :applied-filters="filters"
                                @close="showFilters = false"
                                @filter="handleFilterSubmit"
                                v-if="showFilters"
                            />
                            <q-btn
                                @click.stop="showFilters = !showFilters"
                                outline
                                color="grey"
                                class="my-btn empty q-ml-sm"
                                :class="{'active filters-active': showFilters || activeFilters}"

                            >
                                <template #default>
                                    <q-icon name="svguse:/icons.svg#filter"/>
                                </template>
                            </q-btn>
                        </div>
                        <q-btn
                            outline
                            color="grey"
                            class="my-btn empty q-ml-sm"
                            @click="$emit('refresh')"
                        >
                            <template #default><q-icon name="svguse:/icons.svg#refresh"/></template>
                        </q-btn>
                        <div class="q-ml-md">
                            <q-input
                                style="width: 270px"
                                outlined
                                class="my-input bg-white"
                                v-model="search"
                                lazy-rules
                                placeholder="Hledat"
                                clearable
                                clear-icon="svguse:/icons.svg#close"
                                dense
                            >
                                <template v-slot:prepend>
                                    <q-icon name="svguse:/icons.svg#search" />
                                </template>
                            </q-input>
                        </div>
                    </div>
                </div>
                <q-table
                    v-if="sales"
                    :columns="saleColumns"
                    :data="filteredSales"
                    :pagination.sync="salePagination"
                    class="q-mt-md full-width my-table"
                    row-key="id"
                    dense
                    wrap-cells
                >
                    <template v-slot:pagination="scope">
                        <p class="description q-table__bottom-item q-ml-lg q-mb-none">
                            {{scope.pagination.page}}
                            /
                            {{scope.pagesNumber }}
                        </p>
                        <q-btn
                            class="page-controls"
                            icon="svguse:/icons.svg#carret-left2px"
                            color="grey-8"
                            dense
                            flat
                            :disable="scope.isFirstPage"
                            @click="scope.prevPage"
                        />

                        <q-btn
                            class="page-controls"
                            icon="svguse:/icons.svg#carret-right2px"
                            color="grey-8"
                            dense
                            flat
                            :disable="scope.isLastPage"
                            @click="scope.nextPage"
                        />
                    </template>
                    <template v-slot:body="props">
                        <q-tr :props="props" class="cursor-pointer" @click.native="toggleSelectedSale(props.row)">
                            <q-td
                                key="number"
                                :props="props"
                                :style="`background: ${getEventTypes(props.row)}`"
                            >
                                <div class="color-box">
                                    <p class="body-medium q-mb-sm">{{ props.row.number }}</p>
                                    <p class="small-label q-mb-sm">{{ props.row.isOtc ? 'OTC' : 'Online' }}</p>
                                </div>
                            </q-td>
                            <q-td class="body-medium" key="eventDates" :props="props" v-html="getEventDatesForSale(props.row)" />
                            <q-td class="event-info" key="eventInfo">
                                <p class="body-medium q-mb-sm ellipsis" :props="props">{{ getEventNamesForSale(props.row) }}</p>
                                <p v-if="props.row.tickets" class="event-info__code small-label q-mb-none ellipsis" :props="props" v-html="getTicketCodesCellHtml(props.row)"></p>
                            </q-td>
                            <q-td class="contact-info" key="contactInfo">
                                <p class="body-medium q-mb-sm ellipsis" :props="props" v-html="formatContactInfo(props.row.contactInfo)"></p>
                                <p class="small-label q-mb-none" :props="props">{{ props.row.totalPrice }} Kč</p>
                            </q-td>
                            <q-td key="status" :props="props">
                                <q-icon v-if="props.row.status === 'ordered'" class="q-mr-lg" name="svguse:/icons.svg#annulled-blue" size="20px" title="Nová" />
                                <q-icon v-if="props.row.status === 'paid'" class="q-mr-lg" name="svguse:/icons.svg#check-thin" size="20px" title="Zaplacená" />
                                <q-icon v-if="props.row.status === 'expired'" class="q-mr-lg" name="svguse:/icons.svg#clock" size="20px" title="Vypršelá" />
                                <q-icon v-if="props.row.status === 'canceled'" class="q-mr-lg" name="svguse:/icons.svg#close-red" size="20px" title="Zrušená" />
                                <q-icon v-if="props.row.status === 'annulled'" class="q-mr-lg" name="svguse:/icons.svg#annulled" size="20px" title="Anulovaná" />
                            </q-td>
                            <q-td class="updated" key="updated" :props="props">
                                <p class="body-medium q-mb-sm">{{ formatDate(props.row.updated).slice(0, -5) }}</p>
                                <p class="small-label q-mb-sm">{{ formatDate(props.row.updated).slice(-5) }}</p>
                            </q-td>
                        </q-tr>
                    </template>
                    <template #no-data
                    >
                        <div class="my-table__no-data">
                            <q-icon name="svguse:/icons.svg#empty" size="100px" />
                            <p class="body">
                                Nebyly nalezeny žádné výsledky.
                            </p>
                        </div>
                    </template>
                </q-table>
            </div>
        </div>
</template>

<script>
import { date } from 'quasar'
import SalesFilters from "./SalesFilters";

export default {
    components: {
        SalesFilters,
    },
    data() {
        return {
            saleColumns: [
                { name: 'number', label: 'Objednávka č.', field: 'number', align: 'left', sortable: true },
                { name: 'eventDates', label: 'Datum konání', field: this.getEventDatesForSale, align: 'left', sortable: true, sort: this.sortByDate },
                { name: 'eventInfo', label: 'Název události', field: this.getEventNamesForSale, align: 'left', sortable: true },
                { name: 'contactInfo', label: 'Kupující', field: r => this.formatContactInfo(r.contactInfo), align: 'left', sortable: true },
                { name: 'status', label: 'Stav', field: 'status', align: 'center', sortable: true },
                { name: 'updated', label: 'Změna', field: 'updated', align: 'left', sortable: true },
            ],
            salePagination: {
                sortBy: 'updated',
                descending: true,
                rowsPerPage: 10,
            },
            showSaleLog: false,
            showFilters: false,
            filters: {},
            search: null,
        };
    },
    computed: {
        activeFilters(){
            return this.filters.date || this.filters.type || this.filters.origin || this.filters.state || this.filters.purchaseState;
        },
        filteredSales() {
            let result = this.sales || [];

            // const normalizeString = str => str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            if(this.filters.origin){
                if (this.filters.origin === "otc") {
                    result = result.filter(sale => sale.isOtc === true)
                } else {
                    result = result.filter(sale => sale.isOtc !== true)
                }
            }
            if(this.filters.purchaseState){
                result = result.filter(sale => sale.status === this.filters.purchaseState);
            }
            if(this.filters.type){
                result = result.filter(sale => this.findById(this.uberEvents, sale.uberEventRef).type === this.filters.type);
            }
            if(this.filters.state){
                const ue = this.getUberEventsByState(this.uberEvents, this.filters.state)
                result = result.filter(sale => this.findById(ue, sale.uberEventRef))
            }
            if(this.filters.date){
                const ue = this.getUberEventsByDate(this.uberEvents, this.filters.date)
                result = result.filter(sale => this.findById(ue, sale.uberEventRef))
            }

            if(this.search){
                return this.sales.filter(sale => this.findFilterTextIn(
                    this.search || '',
                    this.getEventNamesForSale(sale),
                    this.getEventDatesForSale(sale),
                    `${sale.totalPrice} Kč`,
                    sale.contactInfo.firstName,
                    sale.contactInfo.lastName,
                    sale.contactInfo.email,
                    sale.contactInfo.phone,
                    this.getStatusText(sale),
                    `${sale.number}`,
                    ...sale.tickets.map(t => t.code).filter(c => c),
                ));
            }
            return result
        },
    },
    methods: {
        clearFilters(){
            this.filters = {};
            this.showFilters = false;
        },
        handleFilterSubmit(filters){
            this.showFilters = false;
            if(_.isEqual(filters, this.filters)){
                return;
            }
            this.filters = {...filters};
        },
        getUberEventsByState(uberEvents, state) {
            return uberEvents.filter(ue => {
                if(state === 'passed'){
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => new Date(e.to) < new Date());
                    }
                    return new Date(ue.to) < new Date();
                }
                if(state === 'current'){
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => new Date(e.from) < new Date() && new Date(e.to) > new Date());
                    }
                    return new Date(ue.from) < new Date() && new Date(ue.to) > new Date();
                }
            });
        },
        getUberEventsByDate(uberEvents, selectedDate){
            if(typeof selectedDate === 'string'){
                return uberEvents.filter(ue=>{
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => date.formatDate(e.from, 'YYYY/MM/DD') === selectedDate || date.formatDate(e.to, 'YYYY/MM/DD') === selectedDate)
                    }
                    return date.formatDate(ue.from, 'YYYY/MM/DD') === selectedDate || date.formatDate(ue.to, 'YYYY/MM/DD') === selectedDate;
                });
            }
            return uberEvents.filter(ue=>{
                if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                    return ue.events.some(e => new Date(e.from) >= new Date(selectedDate.from) && new Date(e.from) <= new Date(selectedDate.to));
                }
                return new Date(ue.from) >= new Date(selectedDate.from) && new Date(ue.from) <= new Date(selectedDate.to);
            })
        },
        toggleSelectedSale(sale) {
            this.selectedSale = (this.selectedSale !== sale) ? sale : null;
        },
        getTicketCodesCellHtml(sale) {
            if (sale.expired || sale.canceled) {
                return ''
            } else {
                return sale.tickets.map(t => t.annulled ? `<span class="text-grey text-strike">${t.code}</span>` : t.code).join(', ')
            }
        },
        getEventNamesForSale(sale) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);

            return this.loc(ue.name);
        },
        getEventTypes(sale) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);
            
            switch (ue.type) {
                case 'koncert': return '#10B981';
                case 'multikoncert': return '#105EB9';
                case 'festival': return '#7B61FF';
                case 'divadlo': return '#FC9821';
                case 'vystava': return '#C92614';
                case 'ostatni': return '#1C9CF8';
            }
        },
        getEventDatesForSale(sale) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);
            let minFrom, maxTo;

            this.withSale(sale, (ue, e, tg, t) => {
                if (!e) return;
                if (!minFrom || dayjs(e.from).isBefore(dayjs(minFrom))) minFrom = e.from;
                if (!maxTo || dayjs(e.to).isAfter(dayjs(maxTo))) maxTo = e.to;
            });

            return this.formatDateRange(minFrom || ue.from, maxTo || ue.to);
        },
        findFilterTextIn(filter, ...vals) {
            const words = filter.toLowerCase().split(' ');

            return words.every(word => vals.some(val => val.toLowerCase().includes(word)));
        },
        sortByDate(a, b) {
            a = a.includes('–') ? a.substr(0, a.indexOf('–') - 1) : a;
            b = b.includes('–') ? b.substr(0, b.indexOf('–') - 1) : b;

            return Math.sign(dayjs(a, this.dateTimeFormat).diff(dayjs(b, this.dateTimeFormat)));
        },
        withSale(sale, fn) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);
            const result = [];

            for (const ticket of sale.tickets) {
                const e = this.findById(ue.events, ticket.eventRef);
                const tg = this.findById((e || ue).ticketGroups, ticket.ticketGroupRef);

                result.push(fn(ue, e, tg, ticket));
            }

            return result;
        },
        hasAnyValidPastTicket(sale) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);
            const eventRefs = _.uniq(sale.tickets.filter(t => !t.annulled).map(t => t.eventRef));
            const events = eventRefs.map(ref => this.findById(ue.events, ref));

            return events.some(e => this.sfIsPast(ue, e));
        },
        getEventName(ue, e, tg, t) {
            return this.loc(e.name);
        },
        getEventDate(ue, e, tg, t) {
            return this.formatDateRange(e.from || ue.from, e.to || (e.from ? null : ue.to));
        },
        getTicketDiscountName(ue, e, tg, t) {
            let discount;

            if (t.globalDiscountRef) {
                discount = this.findById(this.globalDiscounts, t.globalDiscountRef);
            } else if (t.localDiscountRef) {
                discount = this.findById(ue.localDiscounts, t.localDiscountRef);
            }

            return discount ? this.loc(discount.name) : null;
        },
        formatContactInfo(info) {
            return `${info.firstName} ${info.lastName}`
            // <br>${info.email}<br>${this.formatPhone(info.phone)}`;
        },
        getStatusClass(sale) {
            switch (sale.status) {
                case 'ordered': return sale.finalizationError ? 'text-red' : 'text-blue';
                case 'paid': return 'text-green';
                case 'canceled': return 'text-red-10';
                case 'expired': return 'text-orange';
                case 'annulled': return 'text-grey';
                default: return null;
            }
        },
        getStatusText(sale) {
            switch (sale.status) {
                case 'ordered': return sale.finalizationError ? 'CHYBA' : 'Nová';
                case 'paid': return 'Zaplacená';
                case 'canceled': return 'Zrušená';
                case 'expired': return 'Vypršelá';
                case 'annulled': return 'Anulovaná';
                default: return '?';
            }
        },
        createSaleLogEntry([timestamp, text, tooltip]) {
            return (!timestamp || !text) ? null : {
                date: dayjs(timestamp).format('DD/MM/YYYY HH:mm:ss'),
                text,
                tooltip,
            };
        },
        assignPayNo(payNos, orderId) {
            let idx = payNos.indexOf(orderId);

            if (idx !== -1) {
                return idx + 1;
            } else {
                payNos.push(orderId);

                return payNos.length;
            }
        },
        async checkAndComplete(sale) {
            const response = await axios.post('/complete', {
                saleId: sale.id,
            });

            if (response.data) {
                sale = response.data;

                this.updateIn(this.sales, sale);
                this.selectedSale = sale;
            }
        },
        annulSale(sale) {
            this.$q.dialog({
                title: 'Opravdu anulovat objednávku?',
                message: 'Zahrnuté vstupenky budou zneplatněny a platba refundována.',
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                const response = await axios.post('/annul', {
                    saleId: sale.id,
                });

                sale = response.data;

                this.updateIn(this.sales, sale);
                this.selectedSale = sale;
            });
        },
        annulTicket(sale, ticket) {
            this.$q.dialog({
                title: 'Opravdu anulovat vstupenku?',
                message: 'Vstupenka bude zneplatněna a odpovídající část platby refundována.',
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                const response = await axios.post('/annul', {
                    saleId: sale.id,
                    ticketCode: ticket.code,
                });

                sale = response.data;

                this.updateIn(this.sales, sale);
                this.selectedSale = sale;
            });
        },
    },
};
</script>

<style lang="scss">

.updated {
    max-width: 120px;
}

.event-info {
    max-width: 300px;
}

.contact-info {
    max-width: 185px;
}

.color-box {
    width: 131px;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 16px;

    p {
        color: #fff;
    }
}

.sales-list{
    background: $black-50;

    .controls{
        position: sticky;
        top: 0;
        left: 0;
        z-index: 999;
        padding-top: 16px;
        padding-bottom: 16px;
        background: $black-50;
    }
    .page-title{
        background: #fff;
        border-bottom: 1px solid $black-200;
        align-items: center;
        padding: 24px 16px;

        h1 {
            padding: 3.5px 0;
        }
    }
    .wrapper{
        padding: 16px 16px 32px;
        position: relative;
    }
    .filter-wrapper{
        position: relative;
    }
    .filters-active{
        stroke: #fff;
        fill: $black-900 !important;

    }

}

</style>
