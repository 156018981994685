<template>
    <q-card
        class="no-box-shadow bg-white"
    >
        <div class="page-title row items-center justify-start bg-white">
            <q-btn
                class="q-mr-sm"
                flat
                @click="handleGoBack"
            >
                <q-icon class="arrow-back" name="svguse:/icons.svg#arrow-right-dark" size="20px" title="Zpět" />
            </q-btn>
            <p class="body-medium q-mb-none">
                Přehled událostí
            </p>
        </div>
        <div class="validation-info q-mt-lg row items-center wrap q-px-32">
            <span class="inline-block">
                <UberEventChip :data="selectedUberEvent" />
            </span>
            <!-- Validation info -->
            <div
                class="my-chip"
                :class="`text-body2 text-${validationColor}`"
                :style="`background-color: ${validationBg};`"
            >
                <!-- <q-icon :name="validationIcon" :color="validationColor" /> -->
                <span>{{ validationText }}</span>
            </div>
            <div
                v-if="!saveBlocker && someEventSellBlocker"
                class="my-chip"
                :class="`text-body2 text-red`"
                style="background-color: #FCCACA;"
            >
                <!-- <q-icon name="warning" color="orange" /> -->
                <span>Některé z podudálostí nejsou připraveny k prodeji</span>
            </div>
            <div
                v-if="!saveBlocker && someTGSellBlocker"
                class="my-chip"
                :class="`text-body2 text-red`"
                style="background-color: #FCCACA;"
            >
                <!-- <q-icon name="warning" color="orange" /> -->
                <span>Některé z kategorií vstupenek nejsou připraveny k prodeji</span>
            </div>
        </div>

        <!-- Primary data -->
        <q-card-section>
            <div class="q-mx-md">
                <div class="title__wrapper q-mb-xl">
                    <InputInlineHeading
                        :label="$t('label.event_title_cs')"
                        :initial-value="selectedUberEvent && selectedUberEvent.name ? selectedUberEvent.name.cs : ''"
                        size="big"
                        @input="handleNameCs"
                        placeholder="Název události v češtině"
                    />
                    <InputInlineHeading
                        :label="$t('label.event_title_en')"
                        :initial-value="selectedUberEvent && selectedUberEvent.name ? selectedUberEvent.name.en : ''"
                        size="big"
                        @input="handleNameEn"
                        placeholder="Název události v angličtině"
                    />
                    <InputInlineHeading
                        :label="$t('label.event_desc_cs')"
                        :initial-value="selectedUberEvent && selectedUberEvent.subtitle ? selectedUberEvent.subtitle.cs : ''"
                        size="small"
                        @input="handleSubtitleCs"
                        placeholder="Popis události v češtině"
                    />
                    <InputInlineHeading
                        :label="$t('label.event_desc_en')"
                        :initial-value="selectedUberEvent && selectedUberEvent.subtitle ? selectedUberEvent.subtitle.cs : ''"
                        size="small"
                        @input="handleSubtitleE"
                        placeholder="Popis události v angličtině"
                    />
                </div>
                <div class="information__wrapper q-mb-md">
                    <div
                        class="bg-white q-px-md column items-start justify-center"
                        :class="{ 'text-grey': sueIs('multikoncert', 'divadlo') }"
                    >
                        <p class="small-label q-mb-xs">{{ !sueIs('multikoncert', 'divadlo') ? 'Vybrat datum události' : 'Datum události' }}</p>
                        {{ 
                            !sueIs('multikoncert', 'divadlo') ?
                            formatDateRange(selectedUberEvent.from, selectedUberEvent.to, true, !sueIs('vystava')) :
                            filteredEvents && filteredEvents.length ?
                            formatDateRange(filteredEvents[0].from, filteredEvents.at(-1).from, true, false) :
                            '? – ?'
                        }}
                        <DateTimeRangePopup
                            futureOnly
                            :oneDay="!sueIs('festival', 'vystava')"
                            :showTime="!sueIs('vystava')"
                            :defaultTime="defaultEventTime"
                            :from.sync="selectedUberEvent.from"
                            :to.sync="selectedUberEvent.to"
                            :disable="!isEditable || sueIs('multikoncert', 'divadlo')"
                            :key="selectedUberEvent.id"
                        />
                    </div>
                    <div class="bg-white column items-start justify-center">
                        <q-select
                            v-model="selectedUberEvent.venueRef"
                            popup-content-class="my-input-select__option"
                            class="my-input-select venues"
                            dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                            input-debounce="0"
                            behavior="menu"
                            outlined
                            emit-value
                            map-options
                            label="Vybrat lokaci"
                            :display-value="selectedUberEvent.venueRef ? findById(venues, selectedUberEvent.venueRef).name.cs : null"
                            :options="venues"
                            :option-label="o => loc(o.name)"
                            @input="handleVenue"
                        />
                    </div>
                    <div class="bg-white column items-start justify-center">
                        <q-select
                            v-model="selectedUberEvent.promoterRef"
                            popup-content-class="my-input-select__option"
                            class="my-input-select venues"
                            dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                            input-debounce="0"
                            behavior="menu"
                            outlined
                            emit-value
                            map-options
                            label="Vybrat pořadatele"
                            :display-value="selectedUberEvent.promoterRef ? findById(promoters, selectedUberEvent.promoterRef).title : null"
                            :options="promoters"
                            :option-label="o => o.title"
                            @input="handlePromoter"
                        />
                    </div>
                    <div class="bg-white q-px-md column items-start justify-center">
                        <p class="small-label q-mb-xs">Vybrat datum předprodeje</p>
                        <span>
                            <FallbackCascade
                                :format="formatDate"
                                :values="[
                                    selectedUberEvent.publicFrom,
                                    ueFallbackPublicFrom(selectedUberEvent)
                                ]"
                            />
                            <span> – </span>
                            <FallbackCascade
                                :format="formatDate"
                                :values="[
                                    selectedUberEvent.publicTo,
                                    ueFallbackPublicTo(selectedUberEvent)
                                ]"
                            />
                            <em v-if="sueIs('multikoncert', 'divadlo')"> (výchozí pro všechny termíny)</em>
                            <em v-if="sueIs('vystava')"> (výchozí pro všechny akce)</em>
                            <DateTimeRangePopup 
                                showTime
                                :from.sync="selectedUberEvent.publicFrom"
                                :to.sync="selectedUberEvent.publicTo"
                                :fallbackFrom="ueFallbackPublicFrom(selectedUberEvent)"
                                :fallbackFromLabel="sueIs('multikoncert', 'divadlo', 'vystava') ? 'Bez výchozího začátku' : null"
                                :useFallbackFrom="false"
                                :defaultFrom="tomorrow()"
                                :fallbackTo="ueFallbackPublicTo(selectedUberEvent)"
                                :fallbackToLabel="sueIs('multikoncert', 'divadlo', 'vystava') ? 'Bez výchozího konce' :
                                                    'Do začátku události'"
                                :useFallbackTo="true"
                                :disable="!isEditable"
                                :key="selectedUberEvent.id"
                            />
                        </span>
                    </div>
                    <div class="bg-white column items-start justify-center">
                        <FilteredSelect
                            genres
                            v-model="sueGenres"
                            dense
                            use-input
                            use-chips
                            multiple
                            :input-debounce="100"
                            :filterFn="genreFilterFn"
                            :options="genres"
                            :option-value="o => o"
                            option-label="title"
                        />
                    </div>
                    <div class="bg-white q-px-md column items-start justify-center">
                        <p class="small-label" style="margin: 10px 0 0 0;">ID/URL</p>
                        <span v-if="!selectedUberEvent.linkToken" class="text-italic">bude vygenerován při uložení</span>
                        <span v-else-if="selectedUberEvent.linkToken === 'new'" class="text-italic">...</span>
                        <div
                            v-else
                            class="row items-center justify-between"
                            style="width: 100%;"
                        >
                            {{ selectedUberEvent.linkToken }}
                            <div class="row items-center" style="margin-top: -12px;">
                                <q-btn
                                    flat
                                    @click="copyToken(generateUrl(selectedUberEvent.linkToken))"
                                >
                                    <q-icon name="svguse:/icons.svg#copy" size="20px" title="copy" />
                                </q-btn>
                                <q-btn
                                    flat
                                    @click="regenerateLinkToken"
                                >
                                    <q-icon name="svguse:/icons.svg#arrows-clockwise" size="20px" title="regenerovat" />
                                </q-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <q-card-section
                v-if="!isArchived"
                class="row items-center q-my-md"
                :class="selectedUberEvent.id ? 'justify-between' : 'justify-end'"
            >
                <div
                    v-if="selectedUberEvent.id"
                >
                    <q-btn outline class="my-btn my-btn--secondary" label="Kódy pro událost" icon-right="svguse:/icons.svg#download2x" @click="exportCodes(null, ticketsForExport)" />
                </div>
                <div class="q-gutter-sm">
                    <q-btn v-if="!selectedUberEvent.id" class="my-btn no-border my-btn--secondary" outline @click="selectedUberEvent = null">Storno</q-btn>
                    <q-btn v-if="selectedUberEvent.id" class="my-btn no-border my-btn--secondary" outline @click="del">Smazat</q-btn>
                    <q-btn
                        type="submit"
                        style="min-width: 165px; margin-left: 16px;"
                        class="my-btn my-btn--primary no-box-shadow"
                        :disable="saveBlocker"
                        :color="selectedUberEvent.id ? 'grey' : 'black'"
                        :label="selectedUberEvent.id ? $t('update'): $t('add')"
                        @click="save"
                    />
                </div>
            </q-card-section>
        </q-card-section>

        <!-- <q-card-section class="q-card-section q-py-none">
            <div class="row items-stretch justify-start">
                <div
                    class="card-link row items-center justify-center cursor-pointer"
                    :class="{ 'active': showSection === 'showCategories' }"
                    @click="showSection = 'showCategories'"
                >
                    <p class="body-medium q-mb-none">Kategorie vstupenek</p>
                </div>
                <div
                    class="card-link row items-center justify-center cursor-pointer"
                    :class="{ 'active': showSection === 'showEvents' }"
                    @click="showSection = 'showEvents'"
                >
                    <p class="body-medium q-mb-none">Podudálosti</p>
                </div>
                <div
                    class="card-link row items-center justify-center cursor-pointer"
                    :class="{ 'active': showSection === 'showDiscounts' }"
                    @click="showSection = 'showDiscounts'"
                >
                    <p class="body-medium q-mb-none">Lokální slevy</p>
                </div>
            </div>
        </q-card-section> -->

        <!-- <q-card-section
            v-if="showSection === 'showCategories'"
            class="categories-section"
        >
            <div class="categories-section__wrapper">
                <div 
                    class="column"
                >
                    <q-btn
                        v-if="!createMode || active || viewMode"
                        class="my-btn my-btn--primary no-box-shadow q-mb-md"
                        style="width: 350px;"
                        color="black"
                        icon-right="svguse:/icons.svg#plus2px"
                        :disable="!selectedUberEvent.id"
                        @click="showCreateCard"
                    >
                        Přidat kategorii vstupenek
                    </q-btn>
                    <CardCategory
                        v-if="createMode && !active"
                        class="skeleton q-mb-md"
                        @remove="createMode = false"
                    />
                    <div
                        
                    >
                        <CardCategory
                            v-for="category in selectedUberEvent.ticketGroups"
                            :key="category.id"
                            :content="category"
                            class="q-mb-md"
                            @remove="deleteCategory(category)"
                            @click.native="active === category ? active = '' : active = category, toggleTicketGroup(category)"
                        />
                    </div>
                </div>
                <div
                    style="width: 100%;"
                >
                    <CardCategoryCreate
                        v-if="createMode && !viewMode && !active"
                        @create="newTicketGroup"
                        @close="closeEdit"
                    />
                    <CardCategoryCreate
                        v-if="viewMode && active"
                        :content="active"
                        @create="newTicketGroup"
                        @close="closeEdit"
                    />
                    <div
                        v-if="!selectedUberEvent.ticketGroups.length"
                        class="categories-section__empty"
                    >
                        <q-icon name="svguse:/icons.svg#drag-drop-grey" size="48px" title="vložit" />
                        <p class="body text-grey q-mb-none">Žádné kategorie lístků. Vytvořte novou.</p>
                    </div>
                </div>
            </div>
        </q-card-section> -->

        <!-- <q-card-section
            v-if="showSection === 'showEvents'"
            class="events-section"
        >
            <div class="events-section__wrapper">
                <div class="column">
                    <q-btn
                        v-if="!createMode || active || viewMode"
                        class="my-btn my-btn--primary no-box-shadow"
                        style="width: 350px;"
                        color="black"
                        icon-right="svguse:/icons.svg#plus2px"
                        :disable="!selectedUberEvent.id"
                        @click="showCreateCard"
                    >
                        Přidat podudálost
                    </q-btn>
                </div>
                <div
                    style="width: 100%;"
                >
                    <div class="events-section__empty">
                        <q-icon name="svguse:/icons.svg#drag-drop-grey" size="48px" title="vložit" />
                        <p class="body text-grey q-mb-none">Žádné pod-události. Přidejte pod-událost.</p>
                    </div>
                </div>
            </div>
        </q-card-section> -->

        <!-- <q-card-section
            v-if="showSection === 'showDiscounts'"
            class="discounts-section"
        >
            <div class="discounts-section__wrapper">
                <div class="column">
                    <q-btn
                        v-if="!createMode || active || viewMode"
                        class="my-btn my-btn--primary no-box-shadow q-mb-md"
                        style="width: 350px;"
                        color="black"
                        icon-right="svguse:/icons.svg#plus2px"
                        :disable="!selectedUberEvent.id"
                        @click="showCreateCard, newLocalDiscount"
                    >
                        Přidat lokální slevu
                    </q-btn>
                    <CardLocalDiscount
                        v-if="createMode && !active"
                        class="skeleton q-mb-md"
                        @remove="createMode = false"
                    />
                    <div>
                        <CardLocalDiscount
                            v-for="discount in selectedUberEvent.localDiscounts"
                            :key="discount.id + '-' + discount.name"
                            :active="active && (active.id === discount.id)"
                            class="skeleton q-mb-md"
                            :content="{
                                id:discount.id,
                                percentage:(1-discount.multiplier)*100,
                                name:discount.name.cs,

                                }"
                            @remove="deleteDiscount(discount)"
                            @click.native="active === category ? active = '' : active = category"
                        />
                    </div>
                </div>
                <div
                    style="width: 100%;"
                >
                    <CardDiscountCreate @close="closeEdit" :content="active" @create="updateDiscount" v-if="createMode && !viewMode && !active"/>
                    <CardDiscountCreate @close="closeEdit" :content="active" @create="updateDiscount" v-if="viewMode && active"/>
                    <div class="discounts-section__empty">
                        <q-icon name="svguse:/icons.svg#drag-drop-grey" size="48px" title="vložit" />
                        <p class="body text-grey q-mb-none">Žádné lokální slevy. Vytvořte novou.</p>
                    </div>
                </div>
            </div>
        </q-card-section> -->

        <!-- Events, local discounts -->
        <q-card-section class="q-mx-md q-pt-none">
            <div class="row q-col-gutter-md q-pb-lg">
                <div class="col-6" v-if="sueIs('multikoncert', 'festival', 'divadlo', 'vystava')">
                    <div class="row items-center q-mb-sm">
                        <div class="col-grow text-h6">
                            <span v-if="selectedUberEvent.type === 'multikoncert'">Termíny:</span>
                            <span v-else-if="selectedUberEvent.type === 'festival'">Koncerty:</span>
                            <span v-else-if="selectedUberEvent.type === 'divadlo'">Termíny:</span>
                            <span v-else-if="selectedUberEvent.type === 'vystava'">Akce:</span>
                        </div>
                        <q-toggle v-if="showArchivedToggle" v-model="listArchivedEvents" class="q-mx-lg" label="Archivované" />
                        <q-btn v-if="isEditable" round dense size="13px" color="primary" icon="add" @click="newEvent" />
                    </div>
                    <q-table dense wrap-cells :columns="eventColumns" :data="filteredEvents" :visible-columns="visibleEventColumns" :pagination.sync="eventPagination"
                             row-key="id" table-class="clickable bg-half-white" @row-click="(ev, row) => toggleEvent(row)">
                        <template v-slot:body-cell-status="props">
                            <q-td>
                                <q-icon v-if="eAllTgsAux(props.row, selectedUberEvent)" name="mail" color="blue" title="Podudálost je čistě wholesale" />
                                <q-icon v-else-if="eSellBlocker(props.row, selectedUberEvent)" name="warning" color="orange" title="Podudálost není připravena k prodeji" />
                                <q-icon v-else name="check" color="green" title="Podudálost je připravena k prodeji" />
                                <q-icon v-if="eSomeTGSellBlocker(props.row, selectedUberEvent)" name="warning" color="orange" title="Některé z kategorií vstupenek nejsou připraveny k prodeji" />
                            </q-td>
                        </template>
                        <template v-slot:no-data />
                    </q-table>
                </div>
            </div>
            <div class="col-6">
                <div class="row items-center q-mb-sm">
                    <div class="col-grow text-h6">Slevy (lokální):</div>
                    <q-btn v-if="isEditable" round dense color="primary" icon="add" @click="newLocalDiscount" />
                </div>
                <q-table dense :columns="localDiscountColumns" :data="selectedUberEvent.localDiscounts" row-key="id" :pagination="localDiscountPagination" hide-bottom>
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td key="nameCs" :props="props">
                                {{ props.row.name.cs || '-' }}
                                <PopupEdit v-model="props.row.name.cs">
                                    <q-input v-model="props.row.name.cs" dense autofocus />
                                </PopupEdit>
                            </q-td>
                            <q-td key="nameEn" :props="props">
                                {{ props.row.name.en || '-' }}
                                <PopupEdit v-model="props.row.name.en">
                                    <q-input v-model="props.row.name.en" dense autofocus />
                                </PopupEdit>
                            </q-td>
                            <q-td key="percentage" :props="props">
                                <PercentageEdit :row="props.row" @save="if (parseInt($event)) props.row.replacementPrice = null;" />
                            </q-td>
                            <q-td key="replacementPrice" :props="props">
                                {{ props.row.replacementPrice ? `${props.row.replacementPrice} Kč` : '-' }}
                                <PopupEdit v-model.number="props.row.replacementPrice" :validate="v => v >= 0 && v <= 99999"
                                            @save="if (parseInt($event)) props.row.multiplier = null;">
                                    <q-input type="number" v-model.number="props.row.replacementPrice" dense autofocus />
                                </PopupEdit>
                            </q-td>
                            <q-td auto-width>
                                <div class="q-pr-md">
                                    <q-btn flat dense icon="delete" @click="deleteIn(selectedUberEvent.localDiscounts, props.row)" />
                                </div>
                            </q-td>
                        </q-tr>
                    </template>
                </q-table>
            </div>
        </q-card-section>
        <!-- Ticket groups -->
        <template v-if="sueIs('koncert', 'festival', 'ostatni')">
            <q-card-section class="row items-center q-mx-md">
                <div class="col-grow text-h6 q-pa-none">Kategorie vstupenek:</div>
                <!-- <div><q-btn outline color="primary" label="Exportovat kódy pro událost" @click="exportCodes(null, ticketsForExport)" /></div> -->
                <div v-if="isEditable"><q-btn round dense color="primary" icon="add" @click="newTicketGroup(false)" /></div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <TicketGroupList :ticketGroups="selectedUberEvent.ticketGroups" :forEvent="false" :saveFn="save" @refresh="$emit('refresh')" />
            </q-card-section>
        </template>

        <!-- Ticket group templates -->
        <template v-if="sueIs('multikoncert', 'divadlo')">
            <q-card-section class="row items-center q-mx-md">
                <div class="col-grow text-h6">Šablony kategorie vstupenek:</div>
                <q-btn v-if="isEditable" round dense color="primary" icon="add" @click="newTicketGroup(true)" />
            </q-card-section>
            <q-card-section class="q-py-none">
                <TicketGroupList :ticketGroups="selectedUberEvent.ticketGroupTemplates" :forEvent="false" :asTemplates="true" @refresh="$emit('refresh')" />
            </q-card-section>
        </template>

        <!-- Save, delete -->

        <!-- Close -->
        <!-- <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedUberEvent = null;" /> -->
    </q-card>
</template>

<script>
import InputInlineHeading from "./InputInlineHeading/InputInlineHeading.vue";
import CardCategory from './Card/CardCategory/CardCategory.vue';
import CardCategoryCreate from './Card/CardCategory/CardCategoryCreate.vue';
import CardLocalDiscount from "./Card/CardLocalDiscount/CardLocalDiscount";
import CardDiscountCreate from "./Card/CardDiscountCreate/CardDiscountCreate";

export default {
    components: {
        InputInlineHeading,
        CardCategory,
        CardCategoryCreate,
        CardLocalDiscount,
        CardDiscountCreate,
    },
    data() {
        return {
            eventColumns: [
                { name: 'name', label: 'Název', field: this.getEventDisplayName, align: 'left', sortable: true, headerStyle: 'font-weight: 700' },
                { name: 'soldTickets', label: 'Prodané vstupenky', field: this.getSoldInternalTicketCount, align: 'left', sortable: true, headerStyle: 'font-weight: 700' },
                { name: 'remainingTickets', label: 'Volné vstupenky', field: this.getRemainingInternalTicketCount, align: 'left', sortable: true, headerStyle: 'font-weight: 700' },
                { name: 'externalTickets', label: 'Externí vstupenky', field: this.getExternalTicketCount, align: 'left', sortable: true, headerStyle: 'font-weight: 700' },
                { name: 'ticketGroupCount', label: 'Kategorie vstupenek', field: r => r.ticketGroups.length, align: 'left', sortable: true, classes: 'text-grey' },
                { name: 'status', label: 'Status', align: 'left' },
            ],
            eventPagination: {
                rowsPerPage: 5,
            },
            listArchivedEvents: false,
            localDiscountColumns: [
                { name: 'nameCs', label: 'Název (CZ)', field: r => r.name.cs, align: 'left', required: true, sortable: true },
                { name: 'nameEn', label: 'Název (EN)', field: r => r.name.en,  align: 'left', required: true, sortable: true },
                { name: 'percentage', label: 'Výše slevy', field: 'multiplier', align: 'left', sortable: true },
                { name: 'replacementPrice', label: 'Nová cena', field: 'replacementPrice', align: 'left', sortable: true },
                { name: 'delete' },
            ],
            localDiscountPagination: {
                rowsPerPage: 0,
            },
            showSection: 'showCategories',
            createMode: false,
            active: null,
            viewMode: false
        };
    },

    computed: {
        isPast() {
            return dayjs(this.selectedUberEvent.to || this.selectedUberEvent.from).isBefore(dayjs());
        },
        isArchived() {
            return this.selectedUberEvent.archived;
        },
        isEditable() {
            return !this.isPast && !this.isArchived;
        },
        sueGenres: {
            get() {
                return this.selectedUberEvent.genreRefs.map(ref => this.findById(this.genres, ref)).filter(g => g);
            },
            set(value) {
                this.selectedUberEvent.genreRefs = value.map(g => g.id);
            },
        },
        visibleEventColumns() {
            let cols = this.eventColumns;

            if (this.listArchivedEvents) {
                cols = cols.filter(c => c.name !== 'status');
            }

            return cols.map(c => c.name);
        },
        filteredEvents() {
            return this.selectedUberEvent.events.filter(e => this.listArchivedEvents ? e.archived : !e.archived);
        },
        saveBlocker() {
            return (
                this.ueSaveBlocker(this.selectedUberEvent) ||
                (
                    this.selectedEvent &&
                    !this.selectedEvent.archived &&
                    this.wrapMessage("Podudálost:", this.eSaveBlocker(this.selectedEvent, this.selectedUberEvent))
                )
            );
        },
        sellBlocker() {
            return this.ueSellBlocker(this.selectedUberEvent);
        },
        someEventSellBlocker() {
            return this.ueSomeEventSellBlocker(this.selectedUberEvent);
        },
        someTGSellBlocker() {
            return this.ueSomeTGSellBlocker(this.selectedUberEvent);
        },
        allTgsAux() {
            return this.ueAllTgsAux(this.selectedUberEvent);
        },
        validationText() {
            return (
                this.saveBlocker ||
                (this.allTgsAux && "Událost je čistě wholesale") ||
                this.sellBlocker ||
                (this.isPast && "Událost již proběhla") ||
                "Událost je připravena k prodeji"
            );
        },
        validationColor() {
            return (
                (this.isPast || this.isArchived) ? 'white' :
                this.saveBlocker ? 'red' :
                this.allTgsAux ? 'white' :
                this.sellBlocker ? 'red' :
                'green'
            );
        },
        validationBg() {
            return (
                (this.isPast || this.isArchived) ? '#BABABA' :
                this.saveBlocker ? '#FCCACA' :
                this.allTgsAux ? '#1C9CF8' :
                this.sellBlocker ? '#FCCACA' :
                '#D1FAE5'
            );
        },
        validationIcon() {
            return (
                this.isArchived ? 'close' :
                this.saveBlocker ? 'warning' :
                this.allTgsAux ? 'mail' :
                this.sellBlocker ? 'warning' :
                'check'
            );
        },
        ticketsForExport() {
            return this.sales
                .filter(s => s.uberEventRef === this.selectedUberEvent.id && s.status === 'paid')
                .flatMap(s => s.tickets)
                .filter(t => !t.annulled)
                .map(t => t.code)
                .concat([
                    ...this.selectedUberEvent.ticketGroups,
                    ...this.selectedUberEvent.events.flatMap(e => e.ticketGroups),
                ].flatMap(tg => [
                    ...tg.externalTicketCodes,
                    ...(tg.auxTicketCodes || []),
                ]));
        },
    },

    methods: {
        handleNameCs(name) {
            this.selectedUberEvent.name.cs = name
        },
        handleNameEn(name) {
            this.selectedUberEvent.name.en = name
        },
        handleSubtitleCs(sub) {
            this.selectedUberEvent.subtitle.cs = sub
        },
        handleSubtitleEn(sub) {
            this.selectedUberEvent.subtitle.en = sub
        },
        handleVenue(venue) {
            this.selectedUberEvent.venueRef = venue.id
            // console.log(this.venues.filter(v => v.name.cs === venue).id)
        },
        handlePromoter(promoter) {
            this.selectedUberEvent.promoterRef = promoter.id
        },
        handleGoBack() {
            this.$router.push({
                query: null
            })
            this.selectedUberEvent = null
        },
        copyToken(token) {
            navigator.clipboard.writeText(token)

            notifyOk("Zkopírováno")
        },
        genreFilterFn(genre, text) {
            return (
                genre.title.toLowerCase().includes(text.toLowerCase()) &&
                !this.selectedUberEvent.genreRefs.includes(genre.id)
            );
        },
        getEventDisplayName(event) {
            const name = this.loc(event.name);

            switch (this.selectedUberEvent.type) {
                case 'festival':
                case 'vystava':
                    return name;

                case 'multikoncert':
                case 'divadlo':
                    return this.formatDate(event.from, false) + (name ? ` – ${name}` : '');
            }
        },
        getSoldInternalTicketCount(e) {
            return !this.sales ? '...' : _.sumBy((
                this.sales.filter(s => s.uberEventRef === this.selectedUberEvent.id && s.status === 'paid')
            ), s => s.tickets.filter(t => t.eventRef === e.id && !t.annulled).length);
        },
        getRemainingInternalTicketCount(e) {
            return !this.sales ? '...' : _.sumBy(
                e.ticketGroups,
                tg => this.tgx(tg, this.selectedUberEvent, 'ticketCount'),
            ) - this.getSoldInternalTicketCount(e);
        },
        getExternalTicketCount(e) {
            return _.sumBy(e.ticketGroups, 'externalTicketCodes.length');
        },
        async regenerateLinkToken() {
            this.$q.dialog({
                class: 'my-dialog regenerate',
                title: "Opravdu přegenerovat?",
                message: `Událost bude uložena. Původní odkaz bude nenávratně zrušen.`,
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                this.selectedUberEvent.linkToken = 'new';

                await this.save();
            });
        },
        // Save uber-event including events, ticket groups, ticket group templates and local discounts
        async save() {
            // Save selected event to uber-event first, if any
            if (this.selectedEvent && !this.selectedEvent.archived) {
                if (this.selectedEvent.id) {
                    this.updateIn(this.selectedUberEvent.events, this.selectedEvent);
                } else {
                    const max = _.maxBy(this.selectedUberEvent.events, 'id');

                    this.selectedEvent.id = max ? max.id + 1 : 1;
                    this.selectedUberEvent.events.push(this.selectedEvent);
                }
            }

            if (this.selectedUberEvent.id) {
                // Update uber-event via API
                const uberEvent = await this.update('uberEvents', this.selectedUberEvent);

                this.updateIn(this.uberEvents, uberEvent);
                this.selectedUberEvent = uberEvent
            } else {
                // Create uber-event via API
                const uberEvent = await this.create('uberEvents', this.selectedUberEvent);

                this.uberEvents.push(uberEvent);
                this.selectedUberEvent = uberEvent;
            }

            notifyOk("Událost byla uložena.");

            this.selectedEvent = this.selectedEvent && this.findIn(this.selectedUberEvent.events, this.selectedEvent);

            // Note: May select wrong group; fixing this is optional in provisional UI
            this.selectedTicketGroup = this.selectedTicketGroup && this.findIn(this.selectedUberEvent.ticketGroups, this.selectedTicketGroup);
        },
        async del() {
            const { result, archived } = await this.delete('uberEvents', this.selectedUberEvent);

            if (result === 'destroyed') {
                notifyOk("Událost byla smazána.");

                this.deleteIn(this.uberEvents, this.selectedUberEvent);
            } else if (result === 'archived') {
                notifyOk("Událost byla archivována.");

                this.selectedUberEvent.archived = archived;

                this.updateIn(this.uberEvents, this.selectedUberEvent);
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }

            this.selectedUberEvent = null;
        },
        newEvent() {
            this.selectedEvent = this.getNewEvent(this.selectedUberEvent.type);

            // Generate ticket group template instances in new event
            for (const template of this.selectedUberEvent.ticketGroupTemplates) {
                const id = this.nextId(this.selectedEvent.ticketGroups);
                const instance = this.getNewTicketGroup(id, template.id, true);

                this.selectedEvent.ticketGroups.push(instance);
            }
        },
        newTicketGroup(isTemplate) {
            const propName = isTemplate ? 'ticketGroupTemplates' : 'ticketGroups';
            const id = this.nextId(this.selectedUberEvent[propName]);
            const ticketGroup = this.getNewTicketGroup(id);

            this.selectedUberEvent[propName].push(ticketGroup);
            this.selectedTicketGroup = ticketGroup;

            // If group is template, generate instances in events
            if (isTemplate) {
                for (const e of [...this.selectedUberEvent.events, this.selectedEvent]) {
                    if (e) {
                        const id = this.nextId(e.ticketGroups);
                        const instance = this.getNewTicketGroup(id, ticketGroup.id, true);

                        e.ticketGroups.push(instance);
                    }
                }
            }
        },
        newLocalDiscount() {
            const id = this.nextId(this.selectedUberEvent.localDiscounts);
            console.log(id)
            const discount = this.getNewDiscount(false, id);

            this.selectedUberEvent.localDiscounts.push(discount);
        },
        async updateDiscount(item){
            const discount = {
                ...item.original,
                replacementPrice: null,
                label: null,
                name: {
                    cs: item.czTitle,
                    en: item.enTitle
                },
                multiplier: 1 - item.discount / 100,
                isGlobal: false
            }
            // const discount = await this.update(this.selectedUberEvent.localDiscounts, transformed);
            notifyOk(this.$t('notification.general_information-updated'));
            this.updateIn(this.selectedUberEvent.localDiscounts, discount);
            this.active = discount
        },
        showCreateCard(){
            this.active = null
            this.createMode = false
            this.viewMode = false
            setTimeout(()=>{
                this.createMode = true
            }, 1)
        },
        closeEdit() {
            this.active = null
            this.createMode = false
            this.viewMode = false
        },
    },
    mounted() {
        document.querySelector('body').classList.remove('overflow-hidden');
    }
};
</script>

<style lang="scss">

.validation-info {
    gap: 8px !important;
}

.title__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.information__wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 1px;
    background-color: $black-300;
    border: $black-300 solid 1px;
}

.q-px-32 {
    margin-inline: 32px;
}

.q-card-section {
    border-bottom: 1px solid $black-200 !important;
    border-top: 1px solid $black-200;
}

.card-link {
    padding: 24px 32px;
    position: relative;

    &.active::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $black-900;
    }
}

.categories-section {
    background-color: #F9FAFB;

    .categories-section__wrapper {
        padding: 16px 0;
        display: flex;
        gap: 16px;

        .categories-section__empty {
            padding: 48px 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            border: 1px solid $black-300;
        }
    }
}

.events-section {
    background-color: #F9FAFB;

    .events-section__wrapper {
        padding: 16px 0;
        display: flex;
        gap: 16px;

        .events-section__empty {
            padding: 48px 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            border: 1px solid $black-300;
        }
    }
}

.discounts-section {
    background-color: #F9FAFB;

    .discounts-section__wrapper {
        padding: 16px 0;
        display: flex;
        gap: 16px;

        .discounts-section__empty {
            padding: 48px 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            border: 1px solid $black-300;
        }
    }
}

</style>
