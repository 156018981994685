'use strict';

import { merge } from 'lodash';
import df from 'deep-freeze';

import sharedConfig from './shared-config';


export default df(merge({
    admin: {
        defaultEventTime: '19:00',
        showArchivedToggle: true,
    },
    debug: {
        level: 'info',
    },
    storefront: {
        showDevUi: false,
    },
}, sharedConfig));
