<template>
    <div class="uber-event-list" @click="showFilters=false">
        <UberEventNewPopup
            @click.stop=""
            @close="showCreate=false"
            @select="handleCreate"
            v-if="showCreate"/>
        <div class="page-title flex justify-between bg-white">
            <h1 class=" typography-h1 title">
                Události
            </h1>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
        </div>
        <div class="wrapper">
            <div class="controls row justify-between">
                <div class="col-3">
                    <q-btn class="my-btn my-btn--primary full-width"
                           color="black"
                           icon-right="svguse:/icons.svg#plus2px"
                           @click="showCreate = true"
                    >
                        Nová událost
                    </q-btn>
                </div>
                <div class="flex">
                    <div class="filter-wrapper">
                        <UberEventFilters
                            @click.native.stop=""
                            @clear="clearFilters"
                            :applied-filters="filters"
                            @close="showFilters=false"
                            @filter="handleFilterSubmit"
                            v-if="showFilters"
                        />
                        <q-btn
                            @click.stop="showFilters = !showFilters"
                            outline
                            color="grey"
                            class="my-btn empty q-ml-sm"
                            :class="{'active filters-active': showFilters || activeFilters}"

                        >
                            <template #default>
                                <q-icon name="svguse:/icons.svg#filter"/>
                            </template>
                        </q-btn>
                    </div>
                    <q-btn
                        outline
                        color="grey"
                        class="my-btn empty q-ml-sm"
                        @click="$emit('refresh')"
                    >
                        <template #default><q-icon name="svguse:/icons.svg#refresh"/></template>
                    </q-btn>
                    <div class="q-ml-md">
                        <q-input
                            style="width: 270px"
                            outlined
                            class="my-input bg-white"
                            v-model="search"
                            lazy-rules
                            placeholder="Hledat"
                            clearable
                            clear-icon="svguse:/icons.svg#close"
                            dense
                        >
                            <template v-slot:prepend>
                                <q-icon name="svguse:/icons.svg#search" />
                            </template>
                        </q-input>
                    </div>
                </div>
            </div>
            <div>
            <q-table
                class="q-mt-md full-width my-table"
                dense wrap-cells
                :columns="uberEventColumns"
                :data="filteredUberEvents"
                :visible-columns="visibleUberEventColumns"
                :pagination.sync="uberEventPagination"
                :rows-per-page-options="[5, 10, 20, 50, 100]"
                rows-per-page-label="Položek na stránce"
                row-key="id"
                :loading="!uberEvents"
                table-class="clickable"
                @row-click="(ev, row) => toggleUberEvent(row)
                ">
                <template v-slot:pagination="scope">
                    <p class="description q-table__bottom-item q-ml-lg q-mb-none">
                        {{scope.pagination.page}}
                        /
                        {{scope.pagesNumber }}
                    </p>
                    <q-btn
                        class="page-controls"
                        icon="svguse:/icons.svg#carret-left2px"
                        color="grey-8"
                        dense
                        flat
                        :disable="scope.isFirstPage"
                        @click="scope.prevPage"
                    />

                    <q-btn
                        class="page-controls"
                        icon="svguse:/icons.svg#carret-right2px"
                        color="grey-8"
                        dense
                        flat
                        :disable="scope.isLastPage"
                        @click="scope.nextPage"
                    />
                </template>
                <template v-slot:body-cell-type="props">
                    <q-td>
                        <UberEventAvatar :data="props.row" />
                    </q-td>
                </template>
                <template v-slot:body-cell-name="props">
                    <q-td key="desc">
                        <p class="body-medium q-mb-sm">
                            {{ loc(props.row.name)}}
                        </p>
                        <p class="small-label q-mb-none" v-if="props.row.subtitle">{{loc(props.row.subtitle)}}</p>
                    </q-td>
                </template>
                <template v-slot:body-cell-soldTickets="props">
                    <q-td>
                        <p class="body-medium q-mb-sm">
                            {{ getSoldInternalTicketCount(props.row)}}
                        </p>
                        <p class="small-label q-mb-none">Zbývá {{getRemainingInternalTicketCount(props.row)}}</p>
                    </q-td>
                </template>
                <template v-slot:body-cell-date="props">
                    <q-td>
                        <div class="date-cell">
                            <template v-if="props.row.type!=='multikoncert' && props.row.type!=='divadlo'">
                                <span class="body-medium q-mb-sm">{{formattedDate(props.row.from)}}</span>
                                <span class="small-label">
                                {{formattedDateRange(
                                    {
                                        from: props.row.from,
                                        to: props.row.to
                                    }
                                )}}
                            </span>
                            </template>
                            <template v-else>
                                <span v-if="props.row.events && props.row.events.length" class="body-medium q-mb-sm">{{formattedDate(props.row.events[0].from)}}</span>
                                <span v-if="props.row.events && props.row.events.length"  class="small-label">
                                {{formattedDateRange({
                                        from: props.row.events[0].from,
                                        to: props.row.events[props.row.events.length-1].to || props.row.events[props.row.events.length-1].from,
                                    }
                                )}}
                            </span>
                            </template>

                        </div>
                    </q-td>
                </template>
                <template v-slot:body-cell-status="props">
                    <q-td>
                        <q-icon v-if="ueAllTgsAux(props.row)" name="svguse:/icons.svg#whosale" title="Událost je čistě wholesale" />
                        <q-icon v-else-if="ueSellBlocker(props.row)" name="svguse:/icons.svg#warning" color="orange" :title="ueSellBlocker(props.row)" />
                        <q-icon v-else name="svguse:/icons.svg#check-thin" size="20px" color="green" title="Událost je připravena k prodeji" />
                        <q-icon v-if="ueSomeEventSellBlocker(props.row)" name="svguse:/icons.svg#warning" color="orange" title="Některé z podudálostí nejsou připraveny k prodeji" />
                        <q-icon v-if="ueSomeTGSellBlocker(props.row) && !ueSellBlocker(props.row)" name="svguse:/icons.svg#warning" color="orange" title="Některé z kategorií vstupenek nejsou připraveny k prodeji" />
                    </q-td>
                </template>
                <template v-slot:no-data />
                <template #no-data
                >
                    <div class="my-table__no-data">
                        <q-icon name="svguse:/icons.svg#empty" size="100px" />
                        <p class="body">
                            Nebyly nalezeny žádné výsledky.
                        </p>
                    </div>
                </template>
            </q-table>
        </div>
    </div>
</div>

</template>

<script>
import UberEventNewPopup from "./UberEventNewPopup";
import UberEventFilters from "./UberEventFilters";
import { date } from 'quasar'
export default {
    components: {UberEventFilters, UberEventNewPopup},
    data() {
        return {
            uberEventColumns: [
                { name: 'type', label: 'Druh', field: 'type', align: 'left', sortable: true },
                { 
                    name: 'date',
                    label: 'Datum konání',
                    field: this.getEventFromDate,
                    align: 'center',
                    sortable: true,
                },
                { name: 'name', label: 'Název', field: r => this.loc(r.name), align: 'left', sortable: true},
                { name: 'location', label: 'Lokace', field: r => this.getVenueById(r.venueRef), align: 'left', sortable: true, classes:'body-medium' },
                { name: 'soldTickets', label: 'Prodáno', field: this.getSoldInternalTicketCount, align: 'left', sortable: true },
                { name: 'externalTickets', label: 'Externě', field: this.getExternalTicketCount, align: 'left', sortable: true,classes:'body-medium' },
                { name: 'status', label: 'Status', align: 'center' },
            ],
            uberEventPagination: {
                rowsPerPage: 10,
                sortBy: 'date',
                descending: true,
            },
            listArchived: false,
            search: '',
            showCreate: false,
            showFilters: false,
            filters:{
            }
        };
    },
    watch:{
        showCreate(){
            if(document && document.querySelector('body')){
                if(this.showCreate){
                    document.querySelector('body').classList.add('overflow-hidden');
                }else{
                    document.querySelector('body').classList.remove('overflow-hidden');
                }
            }
        }
    },
    computed: {
        activeFilters(){
            return this.filters.date || this.filters.type || this.filters.location || this.filters.state;
        },
        visibleUberEventColumns() {
            let cols = this.uberEventColumns;

            if (this.listArchived) {
                cols = cols.filter(c => c.name !== 'status');
            }

            return cols.map(c => c.name);
        },
        filteredUberEvents() {
            let result = this.uberEvents || [];

            result = result.filter(ue => this.listArchived ? ue.archived : !ue.archived);

            const normalizeString = str=> str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            if(this.filters.location){
                result = result.filter(ue=>ue.venueRef === this.filters.location);
            }
            if(this.filters.type){
                result = result.filter(ue=>ue.type === this.filters.type);
            }
            if(this.filters.state){
                result = this.getUberEventsByState(result, this.filters.state);
            }
            if(this.filters.date){
                result = this.getUberEventsByDate(result, this.filters.date);
            }

            if(!this.search){
                return result;
            }

            return result.filter(ue => {
                return (ue.name.cs && normalizeString(ue.name.cs).includes(normalizeString(this.search)))
                    ||  ( ue.name.en && normalizeString(ue.name.en).includes(normalizeString(this.search)))
                    ||  ( ue.type && normalizeString(ue.type).includes(normalizeString(this.search)))
            })
        },
    },

    methods: {
        clearFilters(){
            this.filters = {};
            this.showFilters = false;
        },

        getVenueById(id) {
            return this.loc(_.find(this.venues, v => v.id === id).name);
        },
        formattedDateRange(date) {
            if(!date.to) {
                return '';
            }
            if(date.from === date.to) {
                return '';
            }
            return this.formatDateRange(
                (date && date.from),
                (date && date.to),
                false,
                false,
            ) || '-';
        },
        formattedDate(date) {
            return this.formatDate(date) || '';
        },
        sortByDate(a, b) {
            a = a.includes('–') ? a.substr(0, a.indexOf('–') - 1) : a;
            b = b.includes('–') ? b.substr(0, b.indexOf('–') - 1) : b;

            console.log(Math.sign(dayjs(a, this.dateTimeFormat).diff(dayjs(b, this.dateTimeFormat))))

            return Math.sign(dayjs(a, this.dateTimeFormat).diff(dayjs(b, this.dateTimeFormat)));
        },
        venueFilterFn(venue, text) {
            return this.loc(venue.name).toLowerCase().includes(text.toLowerCase());
        },
        getEventFromDate(ue) {
            if(ue.type === 'multikoncert' || ue.type === 'divadlo' || ue.events.length){
                if (ue.events && ue.events.length) {
                    return _.minBy(ue.events, 'from').from;
                } else {
                    return null
                }
            }
            return ue.from;
        },
        getSoldInternalTicketCount(ue) {
            return !this.sales ? '...' : _.sumBy((
                this.sales.filter(s => s.uberEventRef === ue.id && s.status === 'paid')
            ), s => s.tickets.filter(t => !t.annulled).length);
        },
        getRemainingInternalTicketCount(ue) {
            return !this.sales ? '...' : _.sumBy([
                ...ue.ticketGroups,
                ...ue.events.flatMap(e => e.ticketGroups),
            ], tg => this.tgx(tg, ue, 'ticketCount')) - this.getSoldInternalTicketCount(ue);
        },
        getExternalTicketCount(ue) {
            return _.sumBy([
                ...ue.ticketGroups,
                ...ue.events.flatMap(e => e.ticketGroups),
            ], 'externalTicketCodes.length');
        },
        handleCreate(val){
            this.showCreate = false;
            this.selectedEvent = null;
            this.selectedUberEvent = this.getNewUberEvent(val);
        },
        handleFilterSubmit(filters){
            this.showFilters = false;
            if(_.isEqual(filters, this.filters)){
                return;
            }
            this.filters = {...filters};
        },
        getUberEventsByState(uberEvents, state) {
            return uberEvents.filter(ue => {
                if(state === 'passed'){
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => new Date(e.to) < new Date());
                    }
                    return new Date(ue.to) < new Date();
                }
                if(state === 'current'){
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => new Date(e.from) < new Date() && new Date(e.to) > new Date());
                    }
                    return new Date(ue.from) < new Date() && new Date(ue.to) > new Date();
                }
            });
        },
        getUberEventsByDate(uberEvents, selectedDate){
            if(typeof selectedDate === 'string'){
                return uberEvents.filter(ue=>{
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => date.formatDate(e.from, 'YYYY/MM/DD') === selectedDate || date.formatDate(e.to, 'YYYY/MM/DD') === selectedDate)
                    }
                    return date.formatDate(ue.from, 'YYYY/MM/DD') === selectedDate || date.formatDate(ue.to, 'YYYY/MM/DD') === selectedDate;
                });
            }
            return uberEvents.filter(ue=>{
                if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                    return ue.events.some(e => new Date(e.from) > new Date(selectedDate.from) && new Date(e.from) < new Date(selectedDate.to));
                }
                return new Date(ue.from) > new Date(selectedDate.from) && new Date(ue.from) < new Date(selectedDate.to);
            })
        }
    },
    mounted() {
        document.querySelector('body').classList.remove('overflow-hidden')
    }
};
</script>
<style lang="scss">
.uber-event-list{
    .controls{
        position: sticky;
        top: 0;
        left: 0;
        z-index: 999;
        padding-top: 16px;
        padding-bottom: 16px;
        background: $black-50;
    }
    .page-title{
        background: #fff;
        border-bottom: 1px solid $black-200;
        align-items: center;
        padding: 24px 16px;
    }
    .wrapper{
        padding: 16px 16px 32px;
        position: relative;
    }
    .filter-wrapper{
        position: relative;
    }
    .filters-active{
        stroke: #fff;
        fill: $black-900 !important;

    }

}
</style>
