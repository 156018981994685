<script>
import removeAccents from 'remove-accents';
import { sync } from 'vuex-pathify';

import * as sharedUtil from '@/../universal/util';

function syncWithClone(whats) {
    return _.mapValues(_.keyBy(whats), (what) => ({
        get() { return this.$store.get(what); },
        set(value) { this.$store.set(what, _.cloneDeep(value)); },
    }));
}

export default {
    constants: {
        dateFormat: 'D. M. YYYY',
        timeFormat: 'H:mm',
        ueTypes: [
            'koncert',
            'multikoncert',
            'festival',
            'divadlo',
            'vystava',
            'ostatni',
        ],
    },

    computed: {
        ...sync([
            'uberEvents',
            'venues',
            'promoters',
            'genres',
            'globalDiscounts',
            'sales',
            'users',
            'settings',

            'lastFetch',

            'selectedTicketGroup',

            'loggedIn',
        ]),
        ...syncWithClone([
            'selectedUberEvent',
            'selectedEvent',
            'selectedVenue',
            'selectedPromoter',
            'selectedGenre',
            'selectedUser',
            'selectedSale',
        ]),
        dateTimeFormat() {
            return `${this.dateFormat} ${this.timeFormat}`;
        },
        defaultEventTime() {
            return appConfig.admin.defaultEventTime;
        },
        showArchivedToggle() {
            return appConfig.admin.showArchivedToggle;
        },
    },

    methods: {
        ...sharedUtil,
        removeAccents,
        loc(obj) {
            return !obj ? null : obj[this.$i18n.locale] || obj.cs || obj.en || null;
        },
        tomorrow() {
            return dayjs().add(1, 'days').startOf('day').format();
        },
        formatDate(date, showTime = true) {
            return !date ? null : dayjs(date).format(showTime ? this.dateTimeFormat : this.dateFormat);
        },
        formatDateRange(from, to, showMissing = false, showTimes = true) {
            if (!from || !to) {
                if (showMissing) {
                    const f = this.formatDate(from, showTimes);
                    const t = this.formatDate(to, showTimes);

                    return `${f || '?'} – ${t || '?'}`;
                } else {
                    return this.formatDate(from || to, showTimes);
                }
            }

            const fd = dayjs(from);
            const td = dayjs(to);
            const format = showTimes ? this.dateTimeFormat : this.dateFormat;

            if (fd.isSame(td))
                return fd.format(format);

            if (fd.isSame(td, 'day'))
                return `${fd.format(this.dateTimeFormat)} – ${td.format(this.timeFormat)}`;

            return `${fd.format(format)} – ${td.format(format)}`;
        },
        formatPhone(phone) {
            if (phone && !phone.startsWith('+') && !phone.startsWith('00')) {
                const triplets = phone.replace(/[\D]/g, '').match(/.{1,3}/g);

                return !triplets ? null : `+420 ${triplets.join(' ')}`;
            } else {
                return phone || null;
            }
        },
        generatePath(token) {
            return !token ? null : `/${this.$i18n.locale}/listky/${token}`;
        },
        generateUrl(token) {
            return !token ? null : `${appConfig.app.baseWebUrl}${this.generatePath(token)}`;
        },
        deCamelCase(str) {
            return str.replace(/([a-z])([A-Z])/g, (_, p1, p2) => `${p1} ${p2.toLowerCase()}`);
        },
        hyphenate(str) {
            return str.replace(/[^A-Za-z0-9]+/g, '-');
        },
        filenamify(value) {
            return this.hyphenate(removeAccents(`${value}`).toLowerCase());
        },
        idEquals(a, b) {
            return (a && a.id) === (b && b.id);
        },

        toggleUberEvent(ue) {
            this.selectedUberEvent = this.idEquals(ue, this.selectedUberEvent) ? null : ue;
        },
        toggleEvent(e) {
            this.selectedEvent = this.idEquals(e, this.selectedEvent) ? null : e;
        },
        toggleTicketGroup(tg) {
            this.selectedTicketGroup = tg === this.selectedTicketGroup ? null : tg;
        },
        toggleVenue(v) {
            this.selectedVenue = this.idEquals(v, this.selectedVenue) ? null : v;
        },
        togglePromoter(p) {
            this.selectedPromoter = this.idEquals(p, this.selectedPromoter) ? null : p;
        },
        toggleGenre(g) {
            this.selectedGenre = this.idEquals(g, this.selectedGenre) ? null : g;
        },
        toggleUser(u) {
            this.selectedUser = this.idEquals(u, this.selectedUser) ? null : u;
        },

        sueIs(...types) {
            return this.ueIs(this.selectedUberEvent, ...types);
        },
        getDiscounts(ticketGroup, uberEvent) {
            const ue = uberEvent || this.selectedUberEvent;

            return !ticketGroup ? [] : [
                ...(this.tgx(ticketGroup, ue, 'globalDiscountRefs').map(ref => this.findById(this.globalDiscounts, ref))),
                ...(this.tgx(ticketGroup, ue, 'localDiscountRefs').map(ref => this.findById(ue.localDiscounts, ref))),
            ];
        },
        tgSoldInternalTicketCount(tg) {
            return !this.sales ? '...' : _.sumBy((
                this.sales.filter(s => s.uberEventRef === this.selectedUberEvent.id && s.status === 'paid')
            ), s => s.tickets.filter(t => (
                t.ticketGroupRef === tg.id &&
                (this.forEvent ? t.eventRef === this.selectedEvent.id : !t.eventRef) &&
                !t.annulled
            )).length);
        },
        exportCodes(sale, codes, forEvent, forGroup) {
            const ue = sale ? this.findById(sale.uberEventRef) : this.selectedUberEvent;
            const e = forEvent ? this.selectedEvent : null;
            const tg = forGroup ? this.selectedTicketGroup : null;

            const text = (codes || sale.tickets.filter(t => !t.annulled).map(t => t.code)).sort().join('\r\n');

            let downloadFileName;

            if (sale) {
                downloadFileName = `export-${sale.id}.txt`;
            } else {
                const dateStr = dayjs((e && e.from) || ue.from).format('YYYYMMDD_HHmm');
                const nameStr = this.filenamify(this.loc(ue.name));
                const groupStr = !tg ? 'all' : this.filenamify(this.tgx(tg, ue, 'label', g => this.loc(g.name)) || tg.id);

                downloadFileName = `${dateStr}_${nameStr}_${groupStr}.txt`;
            }

            this.downloadTextFile(downloadFileName, text);
        },
        downloadTextFile(name, text) {
            this.downloadBlob(name, text, 'text/plain');
        },
        downloadBlob(name, data, type) {
            this.openBlob(name, data, type, true);
        },
        openBlobInNewTab(data, type) {
            this.openBlob(null, data, type, false);
        },
        openBlob(name, data, type, download) {
            const url = window.URL.createObjectURL(new Blob([data], { type }));
            const link = document.createElement('a');

            link.href = url;

            if (download) {
                link.download = name;
            } else {
                link.target = '_blank';
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        async logIn() {
            const response = await axios.post('/authenticate', {
                email: this.email,
                password: this.password,
            });

            const { token, role } = response.data;
            const path = (role === 'seller') ? '/cs/otc' : sessionStorage.getItem('redirectPath') || '/';

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            localStorage.setItem('authToken', token);
            localStorage.setItem('authRole', role);
            sessionStorage.removeItem('redirectPath');

            this.$store.set('resetState');
            this.loggedIn = true;

            this.$router.push(path);
        },
        logOut() {
            delete axios.defaults.headers.common['Authorization'];

            localStorage.removeItem('authToken');
            localStorage.removeItem('authRole');
            sessionStorage.setItem('redirectPath', this.$route.fullPath);

            if (this.$route.meta.isPublic) {
                window.location.reload();
            } else {
                this.$store.set('resetState');
                this.$router.push({ name: 'login' });
            }
        },

        async fetchAll(what) {
            const { data } = await axios(`/${what}`);

            // debug.info(`Fetched ${data.length} ${this.deCamelCase(what)}.`);

            this[what] = data;
        },
        async fetchUpdated(what) {
            const since = this.lastFetch[what];
            const dateStr = since ? dayjs(since).format(dayjs(since).isSame(dayjs(), 'day') ? 'H:mm:ss' : 'D/M/YYYY H:mm:ss') : null;
            const sinceStr = since ? ` updated since ${dateStr}` : '';
            const now = Date.now();
            const { data } = await axios(`/${what}`, { params: since ? { since } : null });

            // debug.info(`Fetched ${data.length} ${this.deCamelCase(what)}${sinceStr}.`);

            if (!this[what]) {
                this[what] = data;
            } else {
                for (const item of data) {
                    const idx = this[what].findIndex(i => i.id === item.id);

                    if (idx !== -1) {
                        this.updateIn(this[what], item);
                    } else {
                        this[what].push(item);
                    }
                }
            }

            this.lastFetch[what] = now;
        },
        async fetchMany(...whats) {
            return await Promise.all(whats.map(this.fetchUpdated));
        },
        async fetchPublicInfo(linkToken) {
            const { data } = await axios(`/info`, { params: { linkToken } });

            for (const what of ['uberEvents', 'venues', 'promoters', 'globalDiscounts']) {
                const items = data[what] || [];

                debug.info(`Fetched ${items.length} ${this.deCamelCase(what)}.`);

                this[what] = items;
            }
        },
        findIn(items, item) {
            return this.findById(items, item.id);
        },
        async create(what, item) {
            const response = await axios.post(`/${what}`, item);

            return response.data;
        },
        async update(what, item) {
            const response = await axios.put(`/${what}/${item.id}`, item);

            return response.data;
        },
        async updateAll(what, items) {
            const response = await axios.put(`/${what}`, items);

            return response.data;
        },
        updateIn(items, item) {
            const idx = items.findIndex(i => i.id === item.id);

            items.splice(idx, 1, item);

            return items;
        },
        async delete(what, item) {
            const response = await axios.delete(`/${what}/${item.id}`);

            return response.data;
        },
        deleteIn(items, item) {
            const idx = items.findIndex(i => i.id === item.id);

            items.splice(idx, 1);

            return items;
        },

        nextId(items) {
            const max = _.maxBy(items, 'id');
            const id = max ? max.id + 1 : 1;

            return id;
        },
        getNewUberEvent(type) {
            return {
                id: null,
                label: null,
                name: {},
                subtitle: {},
                type,
                from: null,
                to: null,
                venueRef: null,
                promoterRef: null,
                genreRefs: [],
                isListed: true,
                publicFrom: null,
                publicTo: null,
                linkToken: null,
                events: [],
                localDiscounts: [],
                ticketGroups: ['koncert', 'ostatni'].includes(type) ? [this.getNewDefaultTicketGroup()] : [],
                ticketGroupTemplates: ['multikoncert', 'divadlo'].includes(type) ? [this.getNewDefaultTicketGroup()] : [],
            };
        },
        getNewEvent(ueType) {
            return {
                id: null,
                label: null,
                name: {},
                from: null,
                to: null,
                venueRef: null,
                isListed: true,
                publicFrom: null,
                publicTo: null,
                linkToken: null,
                ticketGroups: ['vystava'].includes(ueType) ? [this.getNewDefaultTicketGroup()] : [],
            };
        },
        getNewTicketGroup(id, templateRef = null, inherit = null) {
            return {
                id,
                templateRef,
                inherit,
                label: null,
                name: {},
                description: {},
                order: null,
                price: null,
                isListed: templateRef ? null : true,
                publicFrom: null,
                publicTo: null,
                linkToken: null,
                globalDiscountRefs: templateRef ? null : [],
                localDiscountRefs: templateRef ? null : [],
                ticketCount: null,
                externalTicketCodes: [],
            };
        },
        getNewDefaultTicketGroup() {
            return {
                ...this.getNewTicketGroup(1),
                price: this.getSetting('defaultTicketPrice'),
                ticketCount: this.getSetting('defaultTicketCount'),
            };
        },
        getNewVenue() {
            return {
                id: null,
                name: {},
                address: null,
            };
        },
        getNewPromoter() {
            return {
                id: null,
                title: null,
                fullTitle: null,
                ico: null,
                dic: null,
            };
        },
        getNewGenre() {
            return {
                id: null,
                title: null,
            };
        },
        getNewDiscount(isGlobal, id = null) {
            return {
                id,
                label: null,
                name: {},
                isGlobal,
                multiplier: null,
                replacementPrice: null,
            };
        },
        getNewUser() {
            return {
                id: null,
                firstName: null,
                lastName: null,
                email: null,
                password: null,
            };
        },

        getSetting(key) {
            return _.get(this.settings.find(s => s.key === key), 'value');
        },

        translateRefundStatus(status) {
            switch (status) {
                case 'PENDING': return 'vyřizuje se';
                case 'FAILED': return 'selhala';
                case 'FINALIZED': return 'dokončena';
                default: return `? (${status})`;
            }
        },
        getRefundStatusClass(status) {
            switch (status) {
                case 'PENDING': return 'text-blue';
                case 'FAILED': return 'text-red-10';
                case 'FINALIZED': return 'text-green';
                default: return '';
            }
        },
    },
};
</script>

<style lang="scss">
.cursor-default {
    cursor: default !important;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
