<template>
    <form class="card-category-create bg-white" @submit.prevent="submitHandler">
        <div class="card-category-create__top">
            <InputInlineHeading
                label="Interní název"
                initial-value="Ticket category 1"
                size="big"
                @input="handleCustomName"
                placeholder="Název události v češtině"
            />
        </div>

        <div class="card-category-create__top row justify-between">
            <InputInlineHeading
                :label="$t('label.event_title_cs')"
                initial-value="Czech Title"
                size="medium"
                @input="handleNameCs"
                placeholder="Název události v češtině"
            />
            <InputInlineHeading
                :label="$t('label.event_title_en')"
                initial-value="English Title"
                size="medium"
                @input="handleNameEn"
                placeholder="Název události v angličtině"
            />
            <InputInlineHeading
                :label="$t('label.event_desc_cs')"
                initial-value="Czech Description"
                size="small"
                @input="handleSubtitleCs"
                placeholder="Popis události v češtině"
            />
            <InputInlineHeading
                :label="$t('label.event_desc_en')"
                initial-value="English Description"
                size="small"
                @input="handleSubtitleEn"
                placeholder="Popis události v angličtině"
            />
        </div>
        <div class="card-category-create__middle">
            <div class="bg-white q-px-md column items-start justify-center" style="grid-area: 1 / 1 / 2 / 2; position: relative;">
                <q-input
                    class="my-input my-input--simple full-width"
                    label="Cena" type="text"
                />
                <span class="input-options body text-grey">Kč</span>
            </div>
            <div class="bg-white q-px-md column items-start justify-center" style="grid-area: 1 / 2 / 2 / 3;">
                <q-input
                    class="my-input my-input--simple full-width"
                    label="Počet vstupenek" type="text"
                />
            </div>
            <div class="bg-white q-px-md column items-start justify-center" style="grid-area: 1 / 3 / 2 / 4; position: relative;">
                <q-input
                    class="my-input my-input--simple full-width"
                    label="Externí" type="text"
                />
                <div class="input-options">
                    <div
                        class="q-pa-sm"
                        @click="console.log('hallo')"
                    >
                        <q-icon name="svguse:/icons.svg#list-bullets-grey" size="20px" title="download" />
                    </div>
                    <div
                        class="q-pa-sm"
                        @click="console.log('hallo')"
                    >
                        <q-icon name="svguse:/icons.svg#plus-grey" size="20px" title="add" />
                    </div>
                    <div
                        class="q-pa-sm"
                        @click="console.log('hallo')"
                    >
                        <q-icon name="svguse:/icons.svg#minus-grey" size="20px" title="remove" />
                    </div>
                </div>
            </div>
            <div class="bg-white q-px-md column items-start justify-center" style="grid-area: 2 / 1 / 3 / 2;">
                <DiscountSelect
                    dense
                    use-input
                    use-chips
                    multiple
                    :input-debounce="100"
                    placeholder="Vybrat slevu"
                    :options="discountOptions"
                    :option-value="o => o.name.cs"
                    option-label="title"
                />
                <!-- :filterFn="genreFilterFn" -->
            </div>
            <div style="grid-area: 2 / 2 / 3 / 3;" class="bg-white q-px-md column items-start justify-center">
                <p class="small-label q-mb-xs">ID/URL</p>
                <span v-if="!selectedUberEvent.linkToken" class="text-italic">bude vygenerován při uložení</span>
                <span v-else-if="selectedUberEvent.linkToken === 'new'" class="text-italic">...</span>
                <div
                    v-else
                    class="row items-center justify-between"
                    style="width: 100%;"
                >
                    {{ selectedUberEvent.linkToken }}
                    <div class="row items-center">
                        <q-btn
                            flat
                            @click="copyToken(generateUrl(selectedUberEvent.linkToken))"
                        >
                            <q-icon name="svguse:/icons.svg#copy" size="20px" title="copy" />
                        </q-btn>
                        <q-btn
                            flat
                            @click="regenerateLinkToken"
                        >
                            <q-icon name="svguse:/icons.svg#arrows-clockwise" size="20px" title="regenerovat" />
                        </q-btn>
                    </div>
                </div>
            </div>
            <div class="bg-white column items-start justify-center" style="grid-area: 2 / 3 / 4 / 4;">
                <div class="row items-center q-px-md" style="width: 100%; height: 50%;  position: relative;">
                    <q-input
                        class="my-input my-input--simple full-width"
                        label="Auxiliární" type="text"
                    />
                    <q-icon name="svguse:/icons.svg#question" size="20px" title="question" />
                    <div class="input-options">
                        <div
                            class="q-pa-sm"
                            @click="console.log('hallo')"
                        >
                            <q-icon name="svguse:/icons.svg#list-bullets-grey" size="20px" title="download" />
                        </div>
                        <div
                            class="q-pa-sm"
                            @click="console.log('hallo')"
                        >
                            <q-icon name="svguse:/icons.svg#file" size="20px" title="add" />
                        </div>
                        <div
                            class="q-pa-sm"
                            @click="console.log('hallo')"
                        >
                            <q-icon name="svguse:/icons.svg#file-double" size="20px" title="remove" />
                        </div>
                    </div>
                </div>
                <div class="price-toggle row items-center justify-between" style="width: 100%; height: 50%;">
                    <p
                        class="body q-mb-none q-px-md"
                        :class="{ 'text-grey': false }"
                    >
                        Zobrazit cenu
                    </p>
                    <q-toggle class="my-toggle q-px-md" />
                </div>
            </div>
            <div style="grid-area: 3 / 1 / 4 / 3;" class="bg-white q-pa-md column items-start justify-center">
                <p class="small-label q-mb-xs">Vybrat datum předprodeje</p>
                <span>
                    <FallbackCascade
                        :format="formatDate"
                        :values="[
                            selectedUberEvent.publicFrom,
                            ueFallbackPublicFrom(selectedUberEvent)
                        ]"
                    />
                    <span> – </span>
                    <FallbackCascade
                        :format="formatDate"
                        :values="[
                            selectedUberEvent.publicTo,
                            ueFallbackPublicTo(selectedUberEvent)
                        ]"
                    />
                    <em v-if="sueIs('multikoncert', 'divadlo')"> (výchozí pro všechny termíny)</em>
                    <em v-if="sueIs('vystava')"> (výchozí pro všechny akce)</em>
                    <DateTimeRangePopup 
                        showTime
                        :from.sync="selectedUberEvent.publicFrom"
                        :to.sync="selectedUberEvent.publicTo"
                        :fallbackFrom="ueFallbackPublicFrom(selectedUberEvent)"
                        :fallbackFromLabel="sueIs('multikoncert', 'divadlo', 'vystava') ? 'Bez výchozího začátku' : null"
                        :useFallbackFrom="false"
                        :defaultFrom="tomorrow()"
                        :fallbackTo="ueFallbackPublicTo(selectedUberEvent)"
                        :fallbackToLabel="sueIs('multikoncert', 'divadlo', 'vystava') ? 'Bez výchozího konce' :
                                            'Do začátku události'"
                        :useFallbackTo="true"
                        :disable="!isEditable"
                        :key="selectedUberEvent.id"
                    />
                </span>
            </div>
        </div>
        <q-card-section
            v-if="!isArchived"
            class="row items-center q-my-md"
            :class="selectedUberEvent.id ? 'justify-between' : 'justify-end'"
        >
            <div
                v-if="selectedUberEvent.id"
            >
                <q-btn outline class="my-btn my-btn--secondary" label="Kódy pro událost" icon-right="svguse:/icons.svg#download2x" @click="exportCodes(null, ticketsForExport)" />
            </div>
            <div class="q-gutter-sm">
                <q-btn v-if="!selectedUberEvent.id" class="my-btn no-border my-btn--secondary" outline @click="selectedUberEvent = null">Storno</q-btn>
                <q-btn v-if="selectedUberEvent.id" class="my-btn no-border my-btn--secondary" outline @click="del">Smazat</q-btn>
                <q-btn
                    type="submit"
                    style="min-width: 165px; margin-left: 16px;"
                    class="my-btn my-btn--primary no-box-shadow"
                    :disable="saveBlocker"
                    :color="selectedUberEvent.id ? 'grey' : 'black'"
                    :label="selectedUberEvent.id ? $t('update'): $t('add')"
                    @click="save"
                />
            </div>
        </q-card-section>
    </form>
</template>

<script>
import InputInlineHeading from "../../InputInlineHeading/InputInlineHeading";

export default {
    name: "CardDiscountCreate",
    components: {InputInlineHeading},
    props: {
        forEvent: {
            type: Boolean,
            required: true,
        },
        isTemplate: {
            type: Boolean,
            default: false,
        },
        saveFn: Function,
    },
    data() {
        return {
            externalTicketAction: null,
            externalTicketCodes: '',
            showAuxTicketDialog: null,
        };
    },
    computed: {
        isPast() {
            return dayjs(
                (this.forEvent && (this.selectedEvent.to || this.selectedEvent.from)) ||
                this.selectedUberEvent.to || this.selectedUberEvent.from,
            ).isBefore(dayjs());
        },
        isArchived() {
            return this.selectedUberEvent.archived || (this.forEvent && this.selectedEvent.archived);
        },
        isEditable() {
            return !this.isPast && !this.isArchived;
        },
        discountOptions() {
            return [
                ...this.globalDiscounts,
                ...(this.selectedUberEvent.localDiscounts || []),
            ];
        },
        template() {
            return this.tpl(this.selectedTicketGroup, this.selectedUberEvent);
        },
        discounts: {
            get() {
                return this.getDiscounts(this.selectedTicketGroup);
            },
            set(value) {
                this.selectedTicketGroup.globalDiscountRefs = value.filter(d => d.isGlobal).map(d => d.id);
                this.selectedTicketGroup.localDiscountRefs = value.filter(d => !d.isGlobal).map(d => d.id);
            },
        },
        soldInternalTicketCount() {
            return this.tgSoldInternalTicketCount(this.selectedTicketGroup);
        },
        saveBlocker() {
            return this.tgSaveBlocker(this.selectedTicketGroup, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        sellBlocker() {
            return this.tgSellBlocker(this.selectedTicketGroup, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        isSoldOut() {
            return this.soldInternalTicketCount === this.selectedTicketGroup.ticketCount;
        },
        isAux() {
            return this.tgIsAux(this.selectedTicketGroup, this.selectedUberEvent);
        },
        validationText() {
            return (
                this.saveBlocker ||
                (this.isAux ? (this.sellBlocker && "Kategorie je čistě auxiliární") : this.sellBlocker) ||
                (this.isSoldOut && "Kategorie vstupenek je vyprodána") ||
                "Kategorie vstupenek je připravena k prodeji"
            );
        },
        validationColor() {
            return (
                this.saveBlocker ? 'red' :
                this.sellBlocker ? (this.isAux ? 'blue' : 'orange') :
                this.isSoldOut ? 'purple' :
                'green'
            );
        },
        validationIcon() {
            return (
                this.saveBlocker ? 'warning' :
                this.sellBlocker ? (this.isAux ? 'mail' : 'warning') :
                this.isSoldOut ? 'done_all' :
                'check'
            );
        },
        ticketsForExport() {
            return this.sales
                .filter(s => s.uberEventRef === this.selectedUberEvent.id && s.status === 'paid')
                .flatMap(s => s.tickets)
                .filter(t => (
                    (this.forEvent ? t.eventRef === this.selectedEvent.id : !t.eventRef) &&
                    t.ticketGroupRef === this.selectedTicketGroup.id) &&
                    !t.annulled,
                )
                .map(t => t.code)
                .concat(this.selectedTicketGroup.externalTicketCodes)
                .concat(this.selectedTicketGroup.auxTicketCodes || []);
        },
        labelTextClass() {
            return `h6${this.selectedTicketGroup.label || this.tplx('label') ? ' italic' : ''}`;
        },
        showExternalTicketDialog: {
            get() { return !!this.externalTicketAction; },
            set(value) { this.externalTicketAction = value ? 'list' : null; },
        },
        dialogTitle() {
            switch (this.externalTicketAction) {
                case 'list': return "Externí vstupenky";
                case 'add': return "Přidat externí vstupenky";
                case 'remove': return "Odebrat externí vstupenky";
            }
        },
    },
    methods: {
        tplx(path) {
            return this.template && _.get(this.template, path);
        },
        discountFilterFn(discount, text) {
            return (
                this.loc(discount.name).toLowerCase().includes(text.toLowerCase()) &&
                !this.discounts.includes(discount)
            );
        },
        showExternalTickets() {
            this.externalTicketAction = 'list';
            this.externalTicketCodes = this.selectedTicketGroup.externalTicketCodes.join('\n');
        },
        onConfirmExternalTickets() {
            switch (this.externalTicketAction) {
                case 'add':
                    this.selectedTicketGroup.externalTicketCodes = _.uniq([
                        ...this.selectedTicketGroup.externalTicketCodes,
                        ...this.externalTicketCodes.split(/\s+/),
                    ]).sort();
                    break;

                case 'remove':
                    _.pullAll(this.selectedTicketGroup.externalTicketCodes, this.externalTicketCodes.split(/\s+/));
                    break;
            }

            this.onCloseExternalTickets();
        },
        onCloseExternalTickets() {
            this.externalTicketAction = null;
            this.externalTicketCodes = '';
        },
        async regenerateLinkToken() {
            this.$q.dialog({
                title: "Opravdu přegenerovat?",
                message: `Původní odkaz bude nenávratně zrušen a nový vygenerován při příštím uložení.`,
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                this.selectedTicketGroup.linkToken = 'new';
            });
        },
        async downloadAuxTickets(onePdfPerTicket) {
            await this.saveFn();

            const { data } = await axios.post('/aux', {
                params: {
                    uberEventRef: this.selectedUberEvent.id,
                    eventRef: this.selectedEvent?.id,
                    ticketGroupRef: this.selectedTicketGroup.id,
                    onePdfPerTicket,
                }
            }, {
                responseType: 'blob',
            });

            const type = onePdfPerTicket ? 'zip' : 'pdf';

            this.downloadBlob(`aux-tickets.${type}`, data, type);

            this.$emit('refresh');
        },
        // submitHandler() {
        //     if (!this.isValidDiscount(this.discount.discount) || !this.discount.czTitle || !this.discount.enTitle) {
        //         if(!this.discount.czTitle){
        //             this.errors.push({
        //                 key: 'czTitle',
        //                 message: this.$t('validation.discounts_cz-title-missing')
        //             })
        //         }
        //         if(!this.discount.enTitle){
        //             this.errors.push({
        //                 key: 'enTitle',
        //                 message: this.$t('validation.discounts_en-title-missing')
        //             })
        //         }
        //         if(!this.discount.discount){
        //             this.errors.push({
        //                 key: 'discount',
        //                 message: this.$t('validation.discounts_discount-missing')
        //             })}
        //         else if(!this.isValidDiscount(this.discount.discount)){
        //             this.errors.push({
        //                 key: 'discount',
        //                 message: this.$t('validation.discounts_discount-number')
        //             })
        //         }
        //         return
        //     }
        //     if (this.content) {
        //         this.$emit('create', {
        //             original: {...this.content},
        //             ...this.discount
        //         })
        //     } else this.$emit('create', this.discount)
        //     this.copy = {...this.discount}
        // }
    },
    mounted() {
        console.log(this.selectedTicketGroup)
        console.log(this.globalDiscounts)
    }
}
</script>

<style scoped lang="scss">

.card-category-create {
    padding: 0;
    border: 1px solid $black-300;
    &__top {
        margin-top: 24px;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid $black-300;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    &__middle {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 1px;
        background-color: $black-300;
        border-bottom: 1px $black-300 solid;

        .price-toggle {
            border-top: 1px solid $black-100;
        }

        .input-options {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            display: flex;
            align-items: center;
        }
    }
}
.card-create-errors{
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
}
.card-error{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $red-600;
    background: $red-200;

    .q-icon{
        margin-left: 4px;
    }
}
.card-error + .card-error{
    margin-left: 4px;
}
.spacer{
    height: 24px;
}

</style>
